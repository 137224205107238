import { Box, CardMedia } from "@mui/material";
import React from "react";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useFetch from "../../hooks/useFetch";
import BASE_URL from "../../config";
import Def from "../../assets/Def.png";
const Carousel = () => {
  const { data, loading } = useFetch(`/api/v1/officials/get-officials`);
  return (
    <Box
      sx={{
        padding: "5px",
        textAlign: "center",
        mt: 1,
      }}
    >
      {loading ? (
        "Loading"
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }}
          modules={[Navigation, Autoplay]}
          navigation={true}
          rewind={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="myMiniSwiper"
        >
          {data.data &&
            data.data
              ?.filter((p) => p.role === "elected official")
              .map((official, i) => (
                <SwiperSlide key={i} style={{ height: "23rem" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 250,
                      width: "100%",
                      objectFit: "contain",
                      background: "transparent",
                    }}
                    image={
                      official?.file ? `${BASE_URL}/${official.file}` : Def
                    }
                    title="जनप्रतिनिधिकाे तस्विर"
                    key={`media-${i}`}
                  />
                  <p>{official.name}</p>
                  <p>{official?.designation?.name || ""}</p>
                  <p>{official?.phone || ""}</p>
                </SwiperSlide>
              ))}
        </Swiper>
      )}
    </Box>
  );
};

export default Carousel;
