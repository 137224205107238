import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import axios from "axios";
import toast from "react-hot-toast";
import Icons from "../../../data/Icons";
import { flexCenter } from "../../../component/commonStyles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { flexBetween } from "../../../component/commonStyles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  TextField,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Avatar,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import BASE_URL from "../../../config";

// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// ......................... Table Customization Ends ...............................//
const rows = [
  { id: "photo", name: "Photo" },
  {
    id: "official",
    name: "Official",
  },
  { id: "name", name: "Name" },
  { id: "designation", name: "Designation" },
  { id: "phone", name: "Phone" },
  { id: "email", name: "Email" },
  { id: "weight", name: "Weight" },
  { id: "action", name: "Action" },
];

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const DepartmentUpdate = () => {
  const { id } = useParams();
  const [values, setValues] = useState({
    name: "",
    room: "",
    weight: "",
    icon: "",
    desc: "",
    tor: "",
    approvedVacancies: [
      { level: "", approvedVacancy: "", fulfilled: "", emptyPosition: "" },
    ],
  });
  const navigate = useNavigate();

  const {
    loading: departmentLoading,
    data: departmentData,
    reFetch,
  } = useFetch(`/api/v1/departments/get-departments/${id}`);
  // console.log("departmentData Official", departmentData);

  /***********************  READING SPECIFIC DEPARTMENT  ************************/
  useEffect(() => {
    if (departmentData) {
      setValues(departmentData.result);
    }
  }, [departmentData]);
  /***********************  READING SPECIFIC DEPARTMENT ENDS  ********************/
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/departments/update-departments/${id}`,
        values
      );

      if (response?.data?.success) {
        toast.success("Details updated successfully");
        navigate("/dashboard/department");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to update details due to technical reasons");
    }
  };

  const handleAddVacancy = () => {
    setValues((prevValues) => ({
      ...prevValues,
      approvedVacancies: [
        ...prevValues.approvedVacancies,
        { level: "", approvedVacancy: "", fulfilled: "", emptyPosition: "" },
      ],
    }));
  };

  const handleDeleteVacancy = (index) => {
    setValues((prevValues) => ({
      ...prevValues,
      approvedVacancies: prevValues.approvedVacancies.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleDeleteOfficial = async (departmentId, officialId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/v1/departments/delete-departments/${departmentId}/officials/${officialId}`
      );

      if (response.data.message) {
        toast.success(response.data.message);
        reFetch();
      } else {
        toast.error("Failed to delete official");
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to delete official due to technical reasons");
    }
  };

  /*********************** * Pagination State ****************************/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /*********************** * Pagination State Ends****************************/

  return (
    <>
      {departmentLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box m="1.5rem 2.5rem">
          {values && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <InputLabel>शाखाको नाम</InputLabel>
                <TextField
                  variant="standard"
                  name="name"
                  type="text"
                  value={values?.name}
                  onChange={(e) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      name: e.target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel>कोठा नंम्बर</InputLabel>
                <TextField
                  variant="standard"
                  name="room"
                  type="text"
                  value={values.room}
                  onChange={(e) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      room: e.target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel>Weight</InputLabel>
                <TextField
                  required
                  variant="standard"
                  name="weight"
                  type="text"
                  value={values.weight}
                  onChange={(e) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      weight: e.target.value,
                    }))
                  }
                  error={values.weight !== "" && !/^-?\d*$/.test(values.weight)} // Check if the value is not a number
                  helperText={
                    values.weight !== "" && !/^-?\d*$/.test(values.weight)
                      ? "Please enter a number"
                      : ""
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel>Icons </InputLabel>
                {values.icon &&
                Icons.find((icon) => icon.label === values.icon) ? (
                  <span style={{ marginRight: "8px" }}>
                    {React.cloneElement(
                      Icons.find((icon) => icon.label === values.icon).value,
                      { fontSize: "large" } // Add any additional props if needed
                    )}
                  </span>
                ) : (
                  <span style={{ marginRight: "8px" }}>
                    {/* Default icon or message */}
                    Default Icon
                  </span>
                )}

                <FormControl
                  variant="outlined"
                  sx={{ width: 500, maxWidth: "70%", m: 2 }}
                >
                  <InputLabel>Choose an Icon</InputLabel>
                  <Select
                    id="icon"
                    variant="standard"
                    fullWidth
                    name="icon"
                    value={values?.icon}
                    onChange={(e) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        icon: e.target.value,
                      }))
                    }
                    label="Choose an Icon"
                  >
                    {Icons.map((option) => (
                      <MenuItem key={option.id} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>शाखाको परिचय</InputLabel>
                <ReactQuill
                  value={values?.desc}
                  onChange={(content) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      desc: content,
                    }))
                  }
                  modules={modules}
                  formats={formats}
                  placeholder="Write something..."
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>पदाधिकारी</InputLabel>
                <ReactQuill
                  value={values.tor}
                  onChange={(content) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      tor: content,
                    }))
                  }
                  modules={modules}
                  formats={formats}
                  placeholder="Write something..."
                />
              </Grid>
              {values.approvedVacancies.map((vacancy, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={2}>
                    <InputLabel>Level</InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={vacancy.level}
                      onChange={(e) => {
                        const updatedVacancies = [...values.approvedVacancies];
                        updatedVacancies[index].level = e.target.value;
                        setValues((prevValues) => ({
                          ...prevValues,
                          approvedVacancies: updatedVacancies,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel>Approved Vacancy</InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={vacancy.approvedVacancy}
                      onChange={(e) => {
                        const updatedVacancies = [...values.approvedVacancies];
                        updatedVacancies[index].approvedVacancy =
                          e.target.value;
                        setValues((prevValues) => ({
                          ...prevValues,
                          approvedVacancies: updatedVacancies,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel>Fulfilled</InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={vacancy.fulfilled}
                      onChange={(e) => {
                        const updatedVacancies = [...values.approvedVacancies];
                        updatedVacancies[index].fulfilled = e.target.value;
                        setValues((prevValues) => ({
                          ...prevValues,
                          approvedVacancies: updatedVacancies,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel>Empty Position</InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={vacancy.emptyPosition}
                      onChange={(e) => {
                        const updatedVacancies = [...values.approvedVacancies];
                        updatedVacancies[index].emptyPosition = e.target.value;
                        setValues((prevValues) => ({
                          ...prevValues,
                          approvedVacancies: updatedVacancies,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDeleteVacancy(index)}
                    >
                      Delete Vacancy
                    </Button>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12} sx={{ ...flexBetween }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddVacancy}
                >
                  Add Vacancy
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Table sx={{ minWidth: 250 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        {rows.map((row) => (
                          <StyledTableCell key={row.id}>
                            {row.name}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {values?.official
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((off, i) => {
                          return (
                            <StyledTableRow key={i}>
                              <StyledTableCell>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={`${BASE_URL}/${off.file}`}
                                  sx={{ height: "60px", width: "60px" }}
                                />
                              </StyledTableCell>

                              <StyledTableCell>{off.role}</StyledTableCell>
                              <StyledTableCell>{off?.name}</StyledTableCell>

                              <StyledTableCell>
                                {off.designation?.name}
                              </StyledTableCell>
                              <StyledTableCell>{off.phone}</StyledTableCell>
                              <StyledTableCell>{off.email}</StyledTableCell>
                              <StyledTableCell>{off.weight}</StyledTableCell>

                              <StyledTableCell>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteOfficial(
                                      off?.department,
                                      off?._id
                                    )
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={values?.official?.length || 0} // Add null checks to prevent accessing properties of undefined
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          )}
          <Box sx={{ ...flexCenter }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="Submit"
              sx={{ mt: 2, mx: 2 }}
              onClick={handleUpdate}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DepartmentUpdate;
