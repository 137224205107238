import { CloudUpload } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Container,
  Grid,
  InputLabel,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BASE_URL from "../../../config";
import { useAuth } from "../../../context/AuthContext";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ProfileUpdate = () => {
  const [auth, setAuth] = useAuth();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    const { name, phone, file } = auth?.user;
    setName(name || "");
    setPhone(phone || "");
    setFile(file || null);
  }, [auth?.user]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      if (file) formData.append("file", file);

      const { data } = await axios.put(
        `${BASE_URL}/api/v1/auth/update-profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data.success) {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Axios Error:", error);
      toast.error("Unable to update details due to technical reasons");
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Container style={{ marginTop: "2rem" }}>
        <Paper style={{ padding: "1.5rem", height: "40rem" }} elevation={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} align="center">
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                sx={{ mb: 3 }}
              >
                Edit Photo
                <VisuallyHiddenInput
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={(e) => setFile(e.target.files[0])}
                  hidden
                />
              </Button>
              {file ? (
                <Avatar
                  src={
                    typeof file === "string"
                      ? `${BASE_URL}/${file}`
                      : URL.createObjectURL(file)
                  }
                  alt="file"
                  sx={{ width: "8rem", height: "8rem" }}
                />
              ) : null}
            </Grid>

            <Grid item xs={12} sx={{ mr: "10px" }}>
              <InputLabel>Name</InputLabel>
              <TextField
                variant="standard"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ mr: "10px" }}>
              <InputLabel>Phone</InputLabel>
              <TextField
                variant="standard"
                name="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "1rem" }}
            type="submit"
          >
            Edit Profile
          </Button>
        </Paper>
      </Container>
    </form>
  );
};

export default ProfileUpdate;
