import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import Def from "../assets/Def.png"; // Import default image
import BASE_URL from "../config";
import useFetch from "../hooks/useFetch";

const ElectedOfficial = () => {
  const { data } = useFetch(`/api/v1/officials/get-officials`);

  console.log("data", data);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
      <Card>
        {data && data.data ? ( // Check if data exists and contains the expected structure
          data.data
            .filter((p) => p.role === "elected official")
            .map((p) => (
              <Box key={p._id}>
                <CardMedia
                  component="img"
                  sx={{ height: "50vh", objectFit: "contain" }}
                  // Check if p.file exists before constructing the image URL
                  image={p?.file ? `${BASE_URL}/${p.file}` : Def}
                  title="Elected Official Image"
                />
                <CardContent style={{ textAlign: "center", paddingTop: 1 }}>
                  <Typography variant="h5" gutterBottom>
                    {p.name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {p?.designation?.name || ""}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Email: {p.email}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Phone: {p.phone}
                  </Typography>
                </CardContent>
              </Box>
            ))
        ) : (
          // Render a message if data is not available
          <CardContent style={{ textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              No elected officials found.
            </Typography>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default ElectedOfficial;
