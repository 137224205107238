import {
  AdminPanelSettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  Dashboard,
  Group,
  Home,
  HomeWork,
  Person,
  Stars
} from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "../../../component/FlexBetween";

const navItems = [
  {
    text: "Dashboard",
    icon: <Home />,
  },
  {
    text: "CLIENT FACING",
    icon: null,
    goto: null,
  },

  {
    text: "DEPARTMENT",
    icon: <HomeWork />,
  },
  {
    text: "OFFICIAL",
    icon: <Group />,
  },
  {
    text: "DESIGNATION",
    icon: <Stars />,
  },
  {
    text: "PALIKA",
    icon: <Dashboard />,
  },
  {
    text: "WARD",
    icon: <Dashboard />,
  },
  {
    text: "MANAGEMENT",
    icon: null,
  },
  {
    text: "ADMIN",
    icon: <Person />,
  },
  {
    text: "SUPERADMIN",
    icon: <AdminPanelSettingsOutlined />,
  },
];

const Dashsidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.common.main,
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "20px",
              borderColor: "red", // Example border color

              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color="primary.main">
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h6" fontWeight="bold">
                    पाँचपोखरी थाङपाल गाउँपालिका
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                // Check if user is admin to display SUPERADMIN option
                if (text === "SUPERADMIN" && !user?.isAdmin) {
                  return null;
                }

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`dashboard/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Dashsidebar;
