import { CloudUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../../config";

// Photo
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const OfficialUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState(null); // Initialize file state as null
  const [officials, setOfficials] = useState({
    role: "", // Initialize role state as empty string
    name: "",
    phone: "",
    email: "",
    weight: "",
    designation: null, // Initialize designation state as null
    level: "", // Initialize level state as empty string
    department: null, // Initialize department state as null
  });
  const [position, setPosition] = useState([]);
  const [option, setOption] = useState([]);

  const getSpecificOfficial = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/officials/get-officials/${id}`
      );

      if (data?.success) {
        setOfficials(data?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting official data");
    }
  };

  const getAllDesignation = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/designations/get-designations`
      );

      if (data?.success) {
        setPosition(data?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting designations");
    }
  };

  const getAllDepartment = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/departments/get-departments`
      );

      if (data?.success) {
        setOption(data?.sortedDepartments);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting departments");
    }
  };

  useEffect(() => {
    getSpecificOfficial();
    getAllDesignation();
    getAllDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        role: officials.role,
        name: officials.name,
        phone: officials.phone,
        email: officials.email,
        weight: officials.weight,
        designation: officials.designation?._id,
        file: file,
      };

      if (officials.role === "official") {
        requestData.level = officials.level;
        requestData.department = officials.department?._id;
      }

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const url = `${BASE_URL}/api/v1/officials/update-officials/${id}`;
      const formData = new FormData();

      for (const key in requestData) {
        formData.append(key, requestData[key]);
      }

      const { data } = await axios.put(url, formData, { headers });

      if (data?.success) {
        toast.success("Official Updated Successfully");
        navigate("/dashboard/official");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Axios Error:", error);
      toast.error("Something went wrong in updating official");
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Box m="1.5rem 2.5rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel>Role</InputLabel>
              <Select
                required
                value={officials.role || ""}
                onChange={(e) => {
                  setOfficials((prevOfficials) => ({
                    ...prevOfficials,
                    role: e.target.value,
                  }));
                }}
              >
                <MenuItem value="official">Official</MenuItem>
                <MenuItem value="elected official">Elected Official</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Full Name</InputLabel>
            <TextField
              required
              variant="standard"
              fullWidth
              name="name"
              value={officials.name}
              onChange={(e) =>
                setOfficials((prevOfficials) => ({
                  ...prevOfficials,
                  name: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Designation</InputLabel>
              <Select
                value={officials?.designation?._id || ""}
                onChange={(e) => {
                  const selectedDesignation = position.find(
                    (d) => d._id === e.target.value
                  );
                  setOfficials((prevOfficials) => ({
                    ...prevOfficials,
                    designation: selectedDesignation,
                  }));
                }}
              >
                {position?.map((c) => (
                  <MenuItem key={c._id} value={c._id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {officials?.role === "official" && (
              <>
                <InputLabel>Level</InputLabel>
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  name="level"
                  value={officials.level || ""}
                  onChange={(e) =>
                    setOfficials((prevOfficials) => ({
                      ...prevOfficials,
                      level: e.target.value,
                    }))
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {officials?.role === "official" && (
              <>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>Department</InputLabel>
                  <Select
                    value={officials?.department?._id || ""}
                    onChange={(e) => {
                      const selectedDepartment = option.find(
                        (d) => d._id === e.target.value
                      );
                      setOfficials((prevOfficials) => ({
                        ...prevOfficials,
                        department: selectedDepartment,
                      }));
                    }}
                  >
                    {option?.map((c) => (
                      <MenuItem key={c._id} value={c._id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Phone</InputLabel>
            <TextField
              variant="standard"
              name="phone"
              value={officials.phone}
              onChange={(e) =>
                setOfficials((prevOfficials) => ({
                  ...prevOfficials,
                  phone: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Email</InputLabel>
            <TextField
              variant="standard"
              name="email"
              value={officials.email}
              autoComplete="email"
              onChange={(e) =>
                setOfficials((prevOfficials) => ({
                  ...prevOfficials,
                  email: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Weight</InputLabel>
            <TextField
              id="weight"
              type="number"
              variant="standard"
              name="weight"
              value={officials.weight}
              autoComplete="weight"
              onChange={(e) =>
                setOfficials((prevOfficials) => ({
                  ...prevOfficials,
                  weight: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUpload />}
              sx={{ mt: 2 }}
            >
              Edit Photo
              <VisuallyHiddenInput
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
                hidden
              />
            </Button>
            {file ? (
              <Box
                component="img"
                src={URL.createObjectURL(file)}
                alt="file"
                height={200}
                width={200}
              />
            ) : (
              <div className="text-center">
                <CardMedia
                  component="img"
                  src={`${BASE_URL}/${officials?.file}`}
                  alt="Officialfile"
                  height={200}
                  width={200}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default OfficialUpdate;
