import { Box } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import GoBackButton from "../../GoBackButton";

const MainLayout = ({ children, title, description, keywords, author }) => {
  const location = useLocation();

  // Function to determine whether to render the GoBackButton
  const shouldRenderGoBackButton = () => {
    // Exclude home page ("/") from rendering the button
    return location.pathname !== "/";
  };

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mb: "3rem", // Adjust margin bottom according to footer height
            paddingBottom: "50px", // Height of the footer
            "@media only screen and (max-width: 600px)": {
              mb: "5rem",
            },
          }}
        >
          <Outlet />
          {children}
        </Box>
        {/* Conditionally render the GoBackButton component */}
        {shouldRenderGoBackButton() && <GoBackButton />}
      </Box>
      {/* Ensure proper styling for the Box wrapping the Footer */}
      <Footer />
    </HelmetProvider>
  );
};

MainLayout.defaultProps = {
  title: "Digital Citizen Card",
  description:
    "Digital Citizen Card for Panchpokhari Thangpal Rural Municipality",
  keywords: "Digital Citizen Card, Panchpokhari Thangpal Rural Municipality",
  author: "Sonam Sherpa",
};

export default MainLayout;
