import { Add, Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import BASE_URL from "../../../config";
import useFetch from "../../../hooks/useFetch";
// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const tableHeader = [
  { id: 1, name: "सेवा सुविधाको प्रकार" },
  { id: 2, name: "सेवा ग्राहीले पुर्‍याउनु पर्ने प्रक्रिया र प्रमाणहरु" },
  { id: 3, name: "लाग्ने शुल्करदस्तुर" },
  { id: 4, name: "लाग्ने समय" },
  { id: 5, name: "सेवा प्रवाह गर्ने अधिकारी / शाखा" },
  { id: 6, name: "उजुरी सन्ने अधिकारी" },
  { id: 7, name: "कैफियत" },
  { id: 8, name: "Action" },
];
const PalikaLetter = () => {
  const navigate = useNavigate();
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  const {
    data: palikaData,

    reFetch,
  } = useFetch(`/api/v1/palika-letters/get-palika-letters`);
  // console.log("palikaData", palikaData);

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${BASE_URL}/api/v1/palika-letters/delete-palika-letters/${id}`
      );
      if (data?.success) {
        toast.success("विवरण सफलतापूर्वक मेटाइयो");
        reFetch();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(`विवरण मेटाउन क्रममा त्रेटि `);
    }
  };

  /*********************** * Pagination State ****************************/

  const [pagePalika, setPagePalika] = useState(0);
  const [rowsPerPagePalika, setRowsPerPagePalika] = useState(10);

  // Pagination change handlers for the second table
  const handleChangePagePalika = (event, newPage) => {
    setPagePalika(newPage);
  };
  const handleChangeRowsPerPagePalika = (event) => {
    setRowsPerPagePalika(+event.target.value);
    setPagePalika(0);
  };

  // Function to filter data based on search query
  const filteredData = palikaData?.data?.filter((palika) =>
    palika.service.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle Search
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box>
        <Header title="नागरिक बडापत्र (गाउँपालिका)" subtitle="व्यवस्थापन" />
        <Toolbar>
          <TextField
            label="Search by Name"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: "70%", m: 2 }}
          />
          <Link
            to="/dashboard/create-palika-letter"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<Add />}
              sx={{ m: 2 }}
            >
              सेवा थप गर्नुहोस्
            </Button>
          </Link>
        </Toolbar>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "60rem",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                {tableHeader.map((value) => (
                  <StyledTableCell
                    key={value.id}
                    align={value.align}
                    style={{ minWidth: value.minWidth }}
                  >
                    {value.name}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {filteredData &&
                filteredData
                  ?.slice(
                    pagePalika * rowsPerPagePalika,
                    pagePalika * rowsPerPagePalika + rowsPerPagePalika
                  )
                  .map((municipality, i) => {
                    return (
                      <StyledTableRow key={i}>
                        {/* map function */}

                        <StyledTableCell>
                          {municipality.service}
                        </StyledTableCell>
                        <StyledTableCell>
                          {municipality.document}
                        </StyledTableCell>
                        <StyledTableCell>{municipality.price}</StyledTableCell>
                        <StyledTableCell>
                          {municipality.serviceTime}
                        </StyledTableCell>
                        <StyledTableCell>
                          {municipality.provider}
                        </StyledTableCell>
                        <StyledTableCell>
                          {municipality.official}
                        </StyledTableCell>
                        <StyledTableCell>
                          {municipality.remarks}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => navigate(`${municipality._id}`)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(municipality._id)}
                          >
                            <Delete />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={palikaData?.data?.length || 0}
          rowsPerPage={rowsPerPagePalika}
          page={pagePalika}
          onPageChange={handleChangePagePalika}
          onRowsPerPageChange={handleChangeRowsPerPagePalika}
        />
      </Box>
    </Box>
  );
};

export default PalikaLetter;
