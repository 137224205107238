import { Button, Container, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../config";

const FormContainer = styled("div")({
  marginTop: "2rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Form = styled("form")({
  width: "100%",
  maxWidth: 400,
});

const SubmitButton = styled(Button)({
  marginTop: "1rem",
});

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit triggered!"); // Check if this line is printed

    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/auth/reset-password/${token}`,
        { password }
      );
      console.log(response);
      if (response.data) {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <FormContainer>
        <Typography variant="h5">Reset Password</Typography>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="New Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: "1rem" }}
          />

          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Submit
          </SubmitButton>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default ResetPassword;
