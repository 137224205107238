import { PeopleOutlineTwoTone } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { flexCenter } from "../../component/commonStyles";
import useFetch from "../../hooks/useFetch";

const Ward = () => {
  const theme = useTheme();

  const { data } = useFetch(
    "/api/v1/ward-letters/get-ward-letters"
  );
  // console.log("letter", data);

  return (
    <Box m="1.5rem 2.5rem">
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 12, sm: 6, md: 12 }}
      >
        {data.data &&
          data.data.map((letter) => (
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              key={letter._id}
              sx={{ ...flexCenter }}
            >
              <Card
                sx={{
                  ...flexCenter,
                  color: "#3c44b1",
                  borderRadius: "12px",
                  maxWidth: 500,
                  height: "35vh",
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                <Link
                  to={`/ward-detail/${letter._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <CardActionArea>
                    <Typography
                      variant="h4"
                      sx={{ px: 1, textAlign: "center" }}
                    >
                      <PeopleOutlineTwoTone sx={{ fontSize: "8rem" }} />
                    </Typography>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        component="div"
                        sx={{ textAlign: "center" }}
                      >
                        {letter?.service}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Ward;
