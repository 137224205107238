import { PeopleOutlineTwoTone } from "@mui/icons-material";
import { Card, Paper, Typography } from "@mui/material";
import React from "react";

const Header = ({ title, subtitle, icon }) => {
  return (
    <>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "transparent",
          p: 4,
          display: "flex",
          mb: 2,
        }}
      >
        <Card
          sx={{
            display: "inline-block",
            p: 2,
            color: "#3c44b1",
            borderRadius: "12px",
          }}
        >
          <Typography variant="h4" sx={{ px: 1 }}>
            {icon || <PeopleOutlineTwoTone />}
          </Typography>
          {/* {icon} */}
        </Card>
        <div>
          <Typography
            variant="h4"
            color="primary.main"
            fontWeight="bold"
            component="div"
            sx={{ pl: 4 }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            color="secondary.main"
            fontWeight="bold"
            component="div"
            sx={{
              pl: 4,
              opacity: "0.6",
            }}
          >
            {subtitle}
          </Typography>
        </div>
      </Paper>
    </>
  );
};

export default Header;
