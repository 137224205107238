import { PeopleOutlineTwoTone } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { flexCenter } from "../../component/commonStyles";
import useFetch from "../../hooks/useFetch";

const PalikaDetail = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `/api/v1/palika-letters/get-palika-letters/${id}`
  );
  // console.log("PalikaData", data);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Typography variant="body1" color="error">
              {error.message}
            </Typography>
          )}
          {data && data?.data && (
            <>
              <Box
                sx={{
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                  m: 1,
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    p: 2,
                    mb: 1,
                    color: "white",
                    borderRadius: "12px",
                    backgroundColor: "secondary.light",
                  }}
                >
                  <PeopleOutlineTwoTone sx={{ fontSize: "3rem", px: 1 }} />
                </Box>
                <div>
                  <Typography
                    variant="h4"
                    color="secondary.main"
                    fontWeight="bold"
                    component="div"
                    sx={{ pl: 4 }}
                  >
                    {data?.data?.service}
                  </Typography>
                </div>
              </Box>
              <Box m="1.5rem 2.5rem">
                <Grid
                  container
                  spacing={{ xs: 1, md: 1 }}
                  columns={{ xs: 12, sm: 8, md: 12 }}
                >
                  <>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        border: `1px solid ${theme.palette.primary.main}`, // Add border with 1px width and black color
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h4"
                          component="h2"
                          sx={{
                            textAlign: "center",
                            color: "primary.main",
                            m: 3,
                          }}
                        >
                          सेवाग्राहीले पुर्‍याउनु पर्ने प्रक्रिया र प्रमाणहरु
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h2"
                          sx={{
                            textAlign: "center",
                            m: 3,
                          }}
                        >
                          {data?.data?.document}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={12}
                      sx={{
                        ...flexCenter,
                        border: `1px solid ${theme.palette.primary.main}`, // Add border with 1px width and black color
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={6}
                        sx={{
                          ...flexCenter,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                              textAlign: "center",
                              color: "primary.main",
                              m: 3,
                            }}
                          >
                            सेवा प्रवाह गर्ने / शाखा
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                              textAlign: "justify",
                              m: 3,
                            }}
                          >
                            {data?.data?.provider}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={6}
                        sx={{
                          ...flexCenter,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                              color: "primary.main",

                              textAlign: "center",
                              m: 3,
                            }}
                          >
                            उजुरी सुन्ने अधिकारी
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                              textAlign: "justify",
                              m: 3,
                            }}
                          >
                            {data?.data?.official}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={12}
                      sx={{
                        ...flexCenter,
                        border: `1px solid ${theme.palette.primary.main}`, // Add border with 1px width and black color
                      }}
                    >
                      <Grid item xs={12} sm={4} md={6} sx={{ ...flexCenter }}>
                        <Box>
                          <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                              color: "primary.main",
                              textAlign: "center",
                              m: 3,
                            }}
                          >
                            लाग्ने समय
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                              textAlign: "justify",
                              m: 3,
                            }}
                          >
                            {data?.data?.serviceTime}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={6} sx={{ ...flexCenter }}>
                        <Box>
                          <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                              color: "primary.main",
                              textAlign: "center",
                              m: 3,
                            }}
                          >
                            लाग्ने शुल्क र दस्तुर
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                              textAlign: "justify",
                              m: 3,
                            }}
                          >
                            {data?.data?.price}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={12}
                      sx={{
                        ...flexCenter,
                        border: `1px solid ${theme.palette.primary.main}`, // Add border with 1px width and black color
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h4"
                          component="h2"
                          sx={{
                            textAlign: "center",
                            color: "primary.main",
                            m: 3,
                          }}
                        >
                          कैफियत
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h2"
                          sx={{
                            textAlign: "justify",
                            m: 3,
                          }}
                        >
                          {data?.data?.remarks}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PalikaDetail;
