import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import { cFlex, flexMiddleCenter } from "../../component/commonStyles";
import { useAuth } from "../../context/AuthContext";
import BASE_URL from "../../config";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(`${BASE_URL}/api/v1/auth/login`, {
        email,
        password,
      });
      // console.log("Login", res);
      if (res && res.data?.success) {
        setAuth({
          ...auth,
          user: res?.data?.user,
          token: res?.data?.token,
        });
      }
      axios.defaults.headers.common["Authorization"] = res.data.token;

      // console.log("auth", auth);
      localStorage.setItem("auth", JSON.stringify(res.data));
      navigate("/dashboard/dashboard");
      // console.log("Login successful");
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container component="main" sx={{ mt: 6 }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Grid item xs={12}>
            <Box sx={cFlex} component={Link} href="/">
              <Box component="img" src={Logo} sx={{ height: "15rem" }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ ...cFlex, mt: 4 }}>
              <Typography
                variant="h2"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "secondary.dark",
                  "&:hover": {
                    color: "secondary.main",
                  },
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                डिजिटल नागरिक बडापत्र
              </Typography>
              <Typography
                variant="h3"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "primary.dark",
                  "&:hover": {
                    color: "secondary.main",
                  },
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                पाँचपोखरी थाङ्पाल गाउँपालिका
              </Typography>
              <Typography
                variant="h4"
                component="h3"
                color="primary.main"
                fontWeight="bold"
                sx={{
                  textAlign: "center",
                }}
              >
                थाङ्पाल, सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल
              </Typography>
              <Typography
                variant="h5"
                color="secondary.light"
                fontWeight="bold"
                sx={{
                  textAlign: "center",
                }}
              >
                पाँचपोखरी थाङ्पाल समृद्धिको आधार - पर्यटन, कृषि,वन र जलधार
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{ ...flexMiddleCenter }}
        >
          <Box
            sx={{
              ...cFlex,
              my: 8,
              mx: 4,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                // label="Email Address"
                name="email"
                // autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                // label="Password"
                type="password"
                id="password"
                // autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                {/* <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default LoginForm;
