const Colors = [
  { id: 1, colors: "#7cb342" },
  { id: 2, colors: "#ff7C4DFF" },
  { id: 3, colors: "#8d6e63" },
  { id: 4, colors: "#FF0288D1" },
  { id: 5, colors: "#0097a7" },
  { id: 6, colors: "#7c4dff" },
  { id: 7, colors: "#9e9e9e" },
  { id: 8, colors: "#00695c" },
  { id: 9, colors: "#827717" },
  { id: 10, colors: "#1976d2" },
];
export default Colors;
