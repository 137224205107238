import React from "react";

const Dashboard = () => {
  // console.log("hello i am auth from dashboard", auth);

  return (
    <div>
      Dashboard
      {/* <Typography variant="pre">{JSON.stringify(auth, null, 4)}</Typography> */}
    </div>
  );
};

export default Dashboard;
