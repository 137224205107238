import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { TextField, Button, Typography, Container } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const FormContainer = styled("div")({
  marginTop: "2rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Form = styled("form")({
  width: "100%",
  maxWidth: 400,
});

const SubmitButton = styled(Button)({
  marginTop: "1rem",
});

const RegisterForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/auth/register`, {
        name,
        email,
        password,
      });
      console.log("response", response);

      if (response.data.success) {
        toast.success("Registration successful");
        // Handle successful registration (e.g., show success message, redirect)
        navigate("/login");
      }

      console.log("Registration successful");
    } catch (error) {
      toast.error("Error:", error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <FormContainer>
        <Typography variant="h5">Register</Typography>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            variant="outlined"
            // label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: "1rem" }}
          />
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Register
          </SubmitButton>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default RegisterForm;
