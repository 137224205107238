import {
  ArrowDropDownOutlined,
  Menu as MenuIcon,
  Search,
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FlexBetween from "../../../component/FlexBetween";
import BASE_URL from "../../../config";
import { useAuth } from "../../../context/AuthContext";
import Def from "../../../assets/Def.png";

const Dashnav = ({ isSidebarOpen, setIsSidebarOpen, user }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [auth, setAuth] = useAuth();
  // console.log("first", auth);
  // console.log("user", user);
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate("/");
  };
  const handleProfile = () => {
    navigate(`dashboard/profile-update/${auth?.user?._id}`);
  };
  const handleAccount = () => {
    navigate(`dashboard/password-update/${auth?.user?._id}`);
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "primary.main",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.default}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." sx={{ color: "primary.main" }} />
            <IconButton>
              <Search sx={{ color: "primary.main" }} />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <FlexBetween>
            <Box
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Typography
                fontWeight="bold"
                fontSize="0.85rem"
                sx={{ color: "white", wordWrap: "break-word" }}
              >
                Hello! {user.name}
              </Typography>
              <Tooltip title="Open User Settings">
                <IconButton sx={{ p: 0 }}>
                  {/* Use conditional rendering to show the default image */}
                  {user?.file ? (
                    <Avatar
                      alt="User Avatar"
                      src={`${BASE_URL}/${user?.file}`}
                    />
                  ) : (
                    <Avatar alt="User Avatar" src={Def} />
                  )}
                </IconButton>
              </Tooltip>
              <ArrowDropDownOutlined
                sx={{ color: "secondary.main", fontSize: "35px" }}
              />
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={handleProfile}>
                {/* <Link
                  to={`dashboard/profile-update/${auth?.user?._id}`}
                  style={{ textDecoration: "none" }}
                > */}
                Profile Setting
                {/* </Link> */}
              </MenuItem>
              <MenuItem onClick={handleAccount}>
                {/* <Link
                  to={`dashboard/password-update/${auth?.user?._id}`}
                  style={{ textDecoration: "none" }}
                > */}
                Account Setting
                {/* </Link> */}
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                {/* <Link
                  to="/"
                  onClick={handleLogout}
                  style={{ textDecoration: "none" }}
                > */}
                Log Out
                {/* </Link> */}
              </MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Dashnav;
