import parse from "html-react-parser"; // Import parse function
import React from "react";
import { useParams } from "react-router-dom";
import { flexCenter } from "../../component/commonStyles";
import useFetch from "../../hooks/useFetch";

import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import BASE_URL from "../../config";

const rows = [
  {
    id: 1,
    name: "Level",
  },
  {
    id: 2,
    name: "Approved Vacancy",
  },
  {
    id: 3,
    name: "Fulfilled",
  },
  {
    id: 4,
    name: "Empty Position",
  },
];
const list = [
  {
    id: 1,
    name: " फोटो",
  },
  {
    id: 2,
    name: "नाम",
  },
  {
    id: 3,
    name: "तह",
  },
  {
    id: 4,
    name: "पद",
  },
  {
    id: 5,
    name: "सम्पर्क",
  },
  {
    id: 6,
    name: "ईमेल",
  },
];
// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// ......................... Table Customization Ends ...............................//

const DepartmentDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `/api/v1/departments/get-departments/${id}`
  );
  // console.log("data1", data);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {error && (
            <Typography variant="body1" color="error">
              {error.message}
            </Typography>
          )}
          {data && data.result && (
            <Box>
              <Box>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ textAlign: "center", m: 3 }}
                >
                  {data.result.name}
                </Typography>

                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 6, sm: 8, md: 12 }}
                  sx={{ ...flexCenter }}
                >
                  <Grid item xs={10}>
                    <List>
                      <ListItem>
                        <ListItemText sx={{ textAlign: "justify" }}>
                          {/* Use parse function to parse HTML string */}
                          {parse(data.result.desc)}
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText sx={{ textAlign: "justify" }}>
                          {/* Use parse function to parse HTML string */}
                          {parse(data.result.tor)}
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
              {data?.result?.approvedVacancies &&
                data.result.approvedVacancies.length > 0 && (
                  <Box>
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{ textAlign: "center", m: 3 }}
                    >
                      Approved Vacancy Details
                    </Typography>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 6, sm: 8, md: 12 }}
                      sx={{ ...flexCenter }}
                    >
                      <Grid item xs={10}>
                        <TableContainer
                          component={Paper}
                          sx={{
                            maxHeight: "60rem",
                          }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <StyledTableRow>
                                {rows.map((row) => (
                                  <StyledTableCell
                                    key={row.id}
                                    align={row.align}
                                    style={{ minWidth: row.minWidth }}
                                  >
                                    {row.name}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {data.result.approvedVacancies.map((item, i) => {
                                return (
                                  <StyledTableRow key={i}>
                                    <StyledTableCell>
                                      {item.level}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {item.approvedVacancy}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {item.fulfilled}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {item.emptyPosition}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                )}

              {data?.result?.official?.length > 0 && (
                <Box>
                  <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                      textAlign: "center",
                      mt: 3,
                    }}
                  >
                    Employee Details
                  </Typography>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 6, sm: 8, md: 12 }}
                    sx={{ ...flexCenter }}
                  >
                    <Grid item xs={10}>
                      <TableContainer
                        component={Paper}
                        sx={{
                          maxHeight: "60rem",
                          overflow: "auto", // Enable scrolling
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <StyledTableRow>
                              {list.map((row) => (
                                <StyledTableCell
                                  key={row.id}
                                  align={row.align}
                                  style={{ minWidth: row.minWidth }}
                                >
                                  {row.name}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {data?.result?.official?.map((items, i) => {
                              return (
                                <StyledTableRow key={i}>
                                  <StyledTableCell>
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={`${BASE_URL}/${items?.file}`}
                                      sx={{ height: "60px", width: "60px" }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {items.name}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {items.level}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {items.designation?.name}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {items.phone}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {items.email}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
export default DepartmentDetail;
