import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@mui/material";

const Spinner = () => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      // Assign to interval in the outer scope
      setCount((prevValue) => --prevValue);
      count === 0 && navigate("/login");
      return () => clearInterval(interval);
    },100);

    return () => clearInterval(interval);
  }, [count, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h5" textAlign="center" gutterBottom>
        Redirecting in {count} seconds
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
