import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import MyRoute from "./routes/MyRoute";
import AppThemeProvider from "./themes/AppThemeProvider";

function App() {
  return (
    <AppThemeProvider>
      <BrowserRouter>
        <CssBaseline />
        <Toaster />
        <MyRoute />
      </BrowserRouter>
    </AppThemeProvider>
  );
}

export default App;
