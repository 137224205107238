import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../component/layout/dashboard/DashboardLayout";
import MainLayout from "../component/layout/main/MainLayout";
import PageNotFound from "../pages/PageNotFound";
import Admin from "../pages/dashboards/Admin/Admin";
import CreateAdmin from "../pages/dashboards/Admin/CreateAdmin";
import Dashboard from "../pages/dashboards/dashboard/Dashboard";
import Department from "../pages/dashboards/department/Department";
import DepartmentForm from "../pages/dashboards/department/DepartmentForm";
import DepartmentUpdate from "../pages/dashboards/department/DepartmentUpdate";
import Designation from "../pages/dashboards/designation/Designation";
import PalikaLetter from "../pages/dashboards/letter/PalikaLetter";
import PalikaLetterForm from "../pages/dashboards/letter/PalikaLetterForm";
import PalikaLetterUpdate from "../pages/dashboards/letter/PalikaLetterUpdate";
import WardLetter from "../pages/dashboards/letter/WardLetter";
import WardLetterForm from "../pages/dashboards/letter/WardLetterForm";
import WardLetterUpdate from "../pages/dashboards/letter/WardLetterUpdate";
import Official from "../pages/dashboards/official/Official";
import OfficialForm from "../pages/dashboards/official/OfficialForm";
import OfficialUpdate from "../pages/dashboards/official/OfficialUpdate";
import PasswordUpdate from "../pages/dashboards/profile/PasswordUpdate";
import ProfileUpdate from "../pages/dashboards/profile/ProfileUpdate";
import SuperAdmin from "../pages/dashboards/superadmin/SuperAdmin";
import ForgotPassword from "../pages/login/ForgotPassword";
import Login from "../pages/login/Login";
import RegisterForm from "../pages/login/RegisterForm";
import ResetPassword from "../pages/login/ResetPassword";
import ApplicationPage from "../pages/main/ApplicationPage";
import DepartmentDetail from "../pages/main/DepartmentDetail";
import Home from "../pages/main/Home";
import Palika from "../pages/main/Palika";
import PalikaDetail from "../pages/main/PalikaDetail";
import Ward from "../pages/main/Ward";
import WardDetail from "../pages/main/WardDetail";
import AdminRoute from "./AdminRoute";
import PrivateRoute from "./Private";

const MyRoute = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="application-page" element={<ApplicationPage />} />
        <Route path="palika" element={<Palika />} />
        <Route path="palika-detail/:id" element={<PalikaDetail />} />
        <Route path="ward" element={<Ward />} />
        <Route path="ward-detail/:id" element={<WardDetail />} />
        <Route path="department-detail/:id" element={<DepartmentDetail />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<RegisterForm />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<DashboardLayout />}>
          <Route path="dashboard/dashboard" element={<Dashboard />} />
          <Route path="dashboard/department" element={<Department />} />
          <Route
            path="dashboard/create-department"
            element={<DepartmentForm />}
          />
          <Route
            path="dashboard/department/:id"
            element={<DepartmentUpdate />}
          />
          <Route path="dashboard/official" element={<Official />} />
          <Route path="dashboard/create-official" element={<OfficialForm />} />
          <Route path="dashboard/official/:id" element={<OfficialUpdate />} />
          <Route path="dashboard/designation" element={<Designation />} />
          <Route path="dashboard/ward" element={<WardLetter />} />
          <Route
            path="dashboard/create-ward-letter"
            element={<WardLetterForm />}
          />
          <Route path="dashboard/ward/:id" element={<WardLetterUpdate />} />
          <Route path="dashboard/palika" element={<PalikaLetter />} />
          <Route
            path="dashboard/create-palika-letter"
            element={<PalikaLetterForm />}
          />
          <Route path="dashboard/palika/:id" element={<PalikaLetterUpdate />} />
          <Route path="dashboard/admin" element={<Admin />} />
          <Route path="dashboard/create-admin" element={<CreateAdmin />} />
          <Route
            path="dashboard/profile-update/:id"
            element={<ProfileUpdate />}
          />
          <Route
            path="dashboard/password-update/:id"
            element={<PasswordUpdate />}
          />
        </Route>
        <Route element={<AdminRoute />}>
          <Route element={<DashboardLayout />}>
            <Route path="dashboard/superadmin" element={<SuperAdmin />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MyRoute;
