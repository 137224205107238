import React, { useEffect, useState } from "react";
import { CloudUpload } from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import BASE_URL from "../../../config";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const OfficialForm = () => {
  const navigate = useNavigate();
  const [department, setDepartment] = useState(null);
  const [option, setOption] = useState([]);
  const [designation, setDesignation] = useState(null);
  const [option1, setOption1] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [level, setLevel] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [weight, setWeight] = useState("");
  const [file, setFile] = useState(null);

  const getAllDepartment = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/departments/get-departments`
      );
      // console.log("dataDep", data);
      if (data?.success) {
        setOption(data.sortedDepartments);
      }
    } catch (error) {
      toast.error("Something went wrong in getting Department");
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const getAllDesignation = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/designations/get-designations`
      );
      // console.log("designation", data);
      if (data?.success) {
        setOption1(data?.data);
      }
    } catch (error) {
      toast.error("Something went wrong in getting officials");
    }
  };

  useEffect(() => {
    getAllDesignation();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log("file data", file);
    try {
      const formData = new FormData();
      formData.append("role", role);
      formData.append("name", name || ""); // Handle empty string for name
      formData.append("phone", phone || ""); // Handle empty string for phone
      formData.append("email", email || ""); // Handle empty string for email
      formData.append("weight", weight || ""); // Handle empty string for weight
      formData.append("level", level || ""); // Handle empty string for level
      formData.append("designation", designation._id || "");
      // Append department ID only if the role is "official"
      if (role === "official") {
        formData.append("department", department?._id || "");
      }
      // Append file data
      if (file) formData.append("file", file);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const url =
        role === "official"
          ? `${BASE_URL}/api/v1/officials/create-officials/${department?._id}`
          : `${BASE_URL}/api/v1/officials/create-elected-officials`;

      const { data } = await axios.post(url, formData, { headers });

      if (data?.success) {
        toast.success("Official Created Successfully");
        navigate("/dashboard/official");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Axios Error:", error);
      toast.error("Something went wrong in the officials function");
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Box m="1.5rem 2.5rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="role-label-select-standard-label">
                जनप्रतिनिधि/कर्मचारी
              </InputLabel>
              <Select
                required
                variant="standard"
                fullWidth
                labelId="role-label-select-standard-label"
                id="role"
                label="Select Role"
                name="role"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <MenuItem value="elected official">जनप्रतिनिधि</MenuItem>
                <MenuItem value="official">कर्मचारी</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              fullWidth
              label="पूरा नाम"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">पद</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Designation"
                value={designation ? designation._id : ""}
                onChange={(e) => {
                  const selectedDesignation = option1.find(
                    (designation) => designation._id === e.target.value
                  );
                  setDesignation(selectedDesignation);
                }}
              >
                {option1?.map((c) => (
                  <MenuItem key={c._id} value={c._id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {role === "official" ? (
              <TextField
                variant="standard"
                fullWidth
                label="तह"
                name="level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {role === "official" ? (
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="department">शाखा</InputLabel>
                <Select
                  labelId="department"
                  id="demo-simple-select-standard"
                  label="department"
                  value={department ? department._id : ""}
                  onChange={(e) => {
                    const selectedDepartment = option.find(
                      (department) => department._id === e.target.value
                    );
                    setDepartment(selectedDepartment);
                  }}
                >
                  {option?.map((d) => (
                    <MenuItem key={d._id} value={d._id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="सम्पर्क नंम्बर"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="ईमेल"
              name="email"
              value={email}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="weight"
              type="number"
              variant="standard"
              label="Weight"
              name="weight"
              value={weight}
              autoComplete="weight"
              onChange={(e) => setWeight(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUpload />}
              sx={{ mt: 2 }}
            >
              Upload Photo
              <VisuallyHiddenInput
                type="file"
                name="image"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
                hidden
              />
            </Button>
            {file && (
              <Box
                component="img"
                src={URL.createObjectURL(file)}
                alt="official_photo"
                height={200}
                width={200}
              />
            )}
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default OfficialForm;
