import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Gov from "../../assets/Gov.png";
import image from "../../assets/Panchpokhari poster.jpg";
import { flexCenter } from "../../component/commonStyles";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Grid container spacing={2}>
      {/* 1st column */}
      <Grid item xs={12} sm={8}>
        <Box
          sx={{
            padding: "20px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            height: "100%",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              objectFit: "cover",
              flex: "1",
            }}
            alt="logo"
            src={image}
          />
        </Box>
      </Grid>

      {/* 2nd column */}
      <Grid item xs={12} sm={4}>
        <Link
          to="/application-page"
          style={{
            textDecoration: "none",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={Gov}
              alt="Government Logo"
              sx={{
                width: "100%",
                height: "auto",
                maxWidth: "85%",
                maxHeight: "85%",
              }}
            />
            <Button
              variant="contained"
              sx={{
                ...flexCenter,
                borderRadius: "15px",
                ml: 1,
              }}
            >
              <Typography variant="h5" color="white" fontWeight="bold">
                नागरिक बडापत्र मा प्रवेश गर्नुहोस्
              </Typography>
            </Button>
          </Box>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Hero;
