import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Icons from "../../../data/Icons";
import { flexCenter } from "../../../component/commonStyles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import BASE_URL from "../../../config";

const DepartmentForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [room, setRoom] = useState(null);
  const [weight, setWeight] = useState(null);
  const [icon, setIcon] = useState("");
  const [desc, setDesc] = useState("");
  const [tor, setTor] = useState("");
  const [approvedVacancies, setApprovedVacancies] = useState([]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      [{ table: [] }],
      ["clean"], // Add the 'clean' option to remove formats
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const departmentData = {
        name,
        room: room || "",
        weight,
        icon,
        desc: desc || "",
        tor: tor || "",
        approvedVacancies,
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/v1/departments/create-departments`,
        departmentData
      );

      if (data && data.success) {
        toast.success("नयँ शाखा सफल्तापूर्वक सूजना भयो ");
        navigate("/dashboard/department");
      } else {
        toast.error(
          data?.error || "प्राविधिक समस्याका कारण शाखा सिर्जना गर्न अवरोध"
        );
      }
    } catch (error) {
      toast.error("प्राविधिक समस्याका कारण सेवामा अवरोध");
    }
  };

  const handleAddVacancy = () => {
    setApprovedVacancies([
      ...approvedVacancies,
      { level: "", approvedVacancy: "", fulfilled: "", emptyPosition: "" },
    ]);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Box m="1.5rem 2.5rem" sx={{ backgroundColor: "default" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel>शाखाको नाम </InputLabel>
            <TextField
              required
              variant="standard"
              fullWidth
              name="name"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel> शाखा कोठा नंम्बर</InputLabel>
            <TextField
              variant="standard"
              fullWidth
              type="text"
              name="room"
              value={room || ""}
              onChange={(e) => setRoom(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Weight</InputLabel>
            <TextField
              variant="standard"
              fullWidth
              name="weight"
              label="Enter a number"
              value={weight || ""} // Ensure that weight is not null
              onChange={(e) => setWeight(e.target.value)}
              error={weight !== "" && isNaN(weight)}
              helperText={
                weight !== "" && isNaN(weight)
                  ? "Please enter a valid number"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              sx={{ width: 500, maxWidth: "70%", m: 2 }}
            >
              <InputLabel>Choose an Icon</InputLabel>
              <Select
                id="icon"
                variant="standard"
                fullWidth
                name="icon"
                value={icon || ""} // Ensure that icon is not null
                onChange={(e) => setIcon(e.target.value)}
                label="Choose an Icon"
              >
                {Icons.map((option) => (
                  <MenuItem key={option.id} value={option.label}>
                    <Box sx={{ ...flexCenter }}>
                      <Box>{option.label}</Box>
                      <Box sx={{ marginLeft: 2 }}>{option.value}</Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>शाखाको विवरण</InputLabel>
            <ReactQuill
              value={desc || ""}
              onChange={setDesc}
              modules={modules}
              formats={formats}
              placeholder="Write something..."
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>कार्य विवरण</InputLabel>
            <ReactQuill
              value={tor || ""}
              onChange={setTor}
              modules={modules}
              formats={formats}
              placeholder="Write something..."
            />
          </Grid>
          {approvedVacancies.map((vacancy, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={3}>
                <InputLabel>Level</InputLabel>
                <TextField
                  variant="standard"
                  fullWidth
                  value={vacancy.level}
                  onChange={(e) => {
                    const updatedVacancies = [...approvedVacancies];
                    updatedVacancies[index].level = e.target.value;
                    setApprovedVacancies(updatedVacancies);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel>Approved Vacancy</InputLabel>
                <TextField
                  variant="standard"
                  fullWidth
                  value={vacancy.approvedVacancy}
                  onChange={(e) => {
                    const updatedVacancies = [...approvedVacancies];
                    updatedVacancies[index].approvedVacancy = e.target.value;
                    setApprovedVacancies(updatedVacancies);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel>Fulfilled</InputLabel>
                <TextField
                  variant="standard"
                  fullWidth
                  value={vacancy.fulfilled}
                  onChange={(e) => {
                    const updatedVacancies = [...approvedVacancies];
                    updatedVacancies[index].fulfilled = e.target.value;
                    setApprovedVacancies(updatedVacancies);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel>Empty Position</InputLabel>
                <TextField
                  variant="standard"
                  fullWidth
                  value={vacancy.emptyPosition}
                  onChange={(e) => {
                    const updatedVacancies = [...approvedVacancies];
                    updatedVacancies[index].emptyPosition = e.target.value;
                    setApprovedVacancies(updatedVacancies);
                  }}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddVacancy}
            >
              Add Vacancy
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ ...flexCenter }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            sx={{ mt: 2, mx: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default DepartmentForm;
