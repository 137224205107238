import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Dashsidebar from "./Dashsidebar";
import Dashnav from "./Dashnav";
import { useAuth } from "../../../context/AuthContext";

const DashboardLayout = () => {
  const [auth] = useAuth();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // console.log("auth", auth);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Dashsidebar
        user={auth?.user || {}}
        isNonMobile={isNonMobile}
        drawerWidth="300px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Dashnav
          user={auth?.user || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
      </Box>
    </Box>
  );
};
export default DashboardLayout;
