import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
      <Box
        sx={{
          display: "flex",
          minHeight: "65vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "100px",
            fontWeight: 700,
          }}
        >
          404
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: "normal",
          }}
        >
          Oops! Page Not Found
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          sx={{
            color: "black",
            border: "1px solid black",
            textDecoration: "none",
            padding: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          Go Back
        </Button>
      </Box>
  );
};

export default PageNotFound;
