import { Typography } from "@mui/material";
import React from "react";
function ScrollingText() {
  return (
    // eslint-disable-next-line jsx-a11y/no-distracting-elements
    <marquee
      behavior="alternate"
      direction="left"
      sx={{
        scrollamount: "2", // Set the scroll speed
        whiteSpace: "nowrap" // Ensure the text does not wrap
      }}
    >
      {/* Your scrolling text or content goes here */}
      <Typography
        variant="subtitle1"
        component="h6"
        color="white"
        fontWeight="bold"
      >
        डिजिटल नागरिक बडापत्र, पाँचपोखरी थाङपाल गाउँपालिका, थाङपाल,
        सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल हार्दिक स्वागत गर्दछ।
      </Typography>
    </marquee>
  );
}

export default ScrollingText;



