import { Add, Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import BASE_URL from "../../../config";
import Icons from "../../../data/Icons";
import useFetch from "../../../hooks/useFetch";
// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// ......................... Table Customization Ends ...............................//
const tableHeader = [
  { id: 1, name: "शाखा" },
  { id: 2, name: "शाखा कोटा नम्बर" },
  { id: 3, name: "Weight" },
  { id: 4, name: "Icon" },
  { id: 5, name: "Action" },
];

const Department = () => {
  const navigate = useNavigate();
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  /************************ Read All Deparments ****************************/
  const { data, reFetch } = useFetch(`/api/v1/departments/get-departments`);
  // console.log("data1", data);

  /*********************** * Pagination State ****************************/
  const count =
    typeof data?.countTotal === "number" ? Math.max(0, data?.countTotal) : 0;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /*********************** * Pagination State Ends****************************/

  /************************ Handle Delete ****************************/

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);

  const handleDeleteDialogClose = () => {
    setSelectedDepartmentId(null);
    setDeleteDialogOpen(false);
  };
  const handleDeleteDialogOpen = (pId) => {
    setSelectedDepartmentId(pId);
    setDeleteDialogOpen(true);
  };
  const handleDelete = async (pId) => {
    try {
      const { data } = await axios.delete(
        `${BASE_URL}/api/v1/departments/delete-departments/${pId}`
      );
      if (data?.success) {
        toast.success("विवरण सफलतापूर्वक मेटाइयो");
        // getAllDepartment();
        reFetch();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(`विवरण मेटाउन क्रममा त्रेटि `);
    }
  };

  /************************ Handle Delete Ends****************************/

  // Function to filter data based on search query
  const filteredData = data?.sortedDepartments?.filter((department) =>
    department.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle Search
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box>
        <Header title="शाखा" subtitle="व्यवस्थापन" />
        <Toolbar>
          <TextField
            label="Search by Name"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: "70%", m: 2 }}
          />

          <Link
            to="/dashboard/create-department"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<Add />}
              sx={{ m: 2 }}
            >
              शाखा थप गर्नुहोस्
            </Button>
          </Link>
        </Toolbar>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "60rem",
        }}
      >
        <Table stickyHeader arial-label="sticky table">
          <TableHead>
            <StyledTableRow>
              {tableHeader.map((value) => (
                <StyledTableCell
                  key={value.id}
                  align={value.align}
                  style={{ minWidth: value.minWidth }}
                >
                  {value.name}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((department, i) => {
                  return (
                    <StyledTableRow key={i}>
                      {/* map function */}
                      <StyledTableCell>{department.name}</StyledTableCell>
                      <StyledTableCell>{department.room}</StyledTableCell>
                      <StyledTableCell>{department.weight}</StyledTableCell>
                      <StyledTableCell>
                        {/* Display the selected icon here */}
                        {department.icon &&
                        Icons.find((icon) => icon.label === department.icon) ? (
                          <span style={{ marginRight: "8px" }}>
                            {React.cloneElement(
                              Icons.find(
                                (icon) => icon.label === department.icon
                              ).value,
                              { fontSize: "large" } // Add any additional props if needed
                            )}
                          </span>
                        ) : (
                          <span style={{ marginRight: "8px" }}>
                            {/* Default icon or message */}
                            Default Icon
                          </span>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={() => navigate(`${department._id}`)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteDialogOpen(department._id)}
                        >
                          <Delete />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Warning Message before deleting Designation */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Delete Deletion</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this department? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDelete(selectedDepartmentId);
              handleDeleteDialogClose();
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Department;
