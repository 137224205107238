import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ElectedOfficial from "../../component/ElectedOfficial";
import { flexBetween, flexCenter } from "../../component/commonStyles";
import Colors from "../../data/Colors";
import Icons from "../../data/Icons";
import useFetch from "../../hooks/useFetch";
const ApplicationPage = () => {
  const { data } = useFetch(`/api/v1/departments/get-departments`);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (_, newValue) => setSelectedTab(newValue);

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1} sx={{ display: "flex" }}>
            {data.sortedDepartments &&
              data.sortedDepartments.map((department, index) => (
                <Grid
                  item
                  key={department._id}
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ ...flexCenter, mt: 1 }}
                >
                  <Card
                    sx={{
                      backgroundColor: "default",
                      borderRadius: "12px",
                      maxWidth: 500,
                      height: "350px",
                      width: "100%",
                      textDecoration: "none",
                      boxShadow: "1px 3px 8px #4f7fc7",
                    }}
                  >
                    <Link
                      to={
                        index === 0
                          ? "/palika"
                          : index === 1
                          ? "/ward"
                          : `/department-detail/${department._id}`
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <CardMedia
                        height="150px"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          mt: 3,
                        }}
                      >
                        <Box
                          sx={{
                            ...flexCenter,
                            width: "160px",
                            height: "160px",
                            borderRadius: "50%",
                            backgroundColor:
                              Colors[index % Colors.length].colors,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h3"
                            sx={{
                              textAlign: "center",
                              mt: 2,
                              color: "white",
                              "& .MuiSvgIcon-root": { fontSize: "5rem" },
                            }}
                          >
                            {department.icon &&
                            Icons.find(
                              (icon) => icon.label === department.icon
                            ) ? (
                              <span>
                                {React.cloneElement(
                                  Icons.find(
                                    (icon) => icon.label === department.icon
                                  ).value,
                                  { fontSize: "large" }
                                )}
                              </span>
                            ) : (
                              <span style={{ marginRight: "6px" }}>
                                Default Icon
                              </span>
                            )}
                          </Typography>
                        </Box>
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          fontWeight="bold"
                          component="h1"
                          sx={{
                            textAlign: "center",
                            color: "primary.main",
                            "@media (max-width: 1024px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {department.name}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h6"
                          fontWeight="bold"
                          component="h1"
                          sx={{
                            textAlign: "center",
                            color: "primary.main",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            "@media (max-width: 1024px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {department.room
                            ? `कोठा नंम्बर - ${department.room}`
                            : ""}
                        </Typography>
                      </CardContent>

                      {/* <CardContent
                        sx={{
                          flex: "1 1 auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ ...flexCenter, height: "160px" }}>
                          <Box
                            sx={{
                              width: "160px",
                              height: "160px",
                              borderRadius: "50%",
                              backgroundColor:
                                Colors[index % Colors.length].colors,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mb: 4,
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="h3"
                              sx={{
                                textAlign: "center",
                                mt: 2,
                                color: "white",
                                "& .MuiSvgIcon-root": { fontSize: "5rem" },
                              }}
                            >
                              {department.icon &&
                              Icons.find(
                                (icon) => icon.label === department.icon
                              ) ? (
                                <span>
                                  {React.cloneElement(
                                    Icons.find(
                                      (icon) => icon.label === department.icon
                                    ).value,
                                    { fontSize: "large" }
                                  )}
                                </span>
                              ) : (
                                <span style={{ marginRight: "6px" }}>
                                  Default Icon
                                </span>
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography
                            gutterBottom
                            variant="h6"
                            fontWeight="bold"
                            component="div"
                            sx={{
                              textAlign: "center",
                              color: "primary.main",
                            }}
                          >
                            {department.name}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h5"
                            fontWeight="bold"
                            component="div"
                            sx={{
                              textAlign: "center",
                              color: "primary.main",
                            }}
                          >
                            {department.room
                              ? `कोठा नंम्बर - ${department.room}`
                              : ""}
                          </Typography>
                        </Box>
                      </CardContent> */}
                    </Link>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              position: "sticky",
              top: "64px", // Adjust according to your navbar height
              maxHeight: "calc(100vh - 64px)", // Adjust according to your navbar height
              overflowY: "auto",
              bgcolor: "#cfe8fc", // Set the background color here
              px: 1,
              py: 1,
              width: "100%", // Ensure full width
            }}
          >
            <Tabs
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              value={selectedTab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-flexContainer": { ...flexBetween },
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                  "& .MuiTab-root": {
                    width: "100%",
                  },
                },
              }}
            >
              <Tab label="Official" disableRipple sx={{ height: "50px" }} />
              {/* <Tab label="News" disableRipple sx={{ height: "50px" }} /> */}
              {/* <Tab label="Gallery" disableRipple sx={{ height: "50px" }} /> */}
            </Tabs>
            <Box sx={{ overflowY: "auto", height: "calc(100% - 50px)" }}>
              {selectedTab === 0 && <ElectedOfficial />}
              {/* {selectedTab === 1 && <News />} */}
              {/* {selectedTab === 2 && <Gallery />} */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplicationPage;
