import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#4f7fc7",
      main: "#2460b9",
      dark: "#194381",
    },
    secondary: {
      light: "#dd3333",
      main: "#d50000",
      dark: "#950000",
    },
    background: {
      default: "#b3e5fc",
    },
    shape: {
      borderRadius: "12px",
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Arya", "Roboto"].join(","),
      textTransform: "none",
      wordWrap: "break-word",
      sx: {
        "@media only screen and (max-width: 600px)": {
          fontSize: "0.8rem",
        },
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        sx: {
          px: 1,
        },
        variant: "subtitle2",
        textTransform: "capitalize",
      },
    },

    MuiLink: {
      defaultProps: {
        sx: {
          color: (theme) => theme.palette.primary.main,
        },
        underline: "none",
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        "&:hover": {
          color: "red",
        },
      },
    },
  },
});

const AppThemeProvider = (prop) => {
  return <ThemeProvider theme={theme}> {prop.children} </ThemeProvider>;
};

export default AppThemeProvider;
