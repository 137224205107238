import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Header from "../../../component/Header";
import BASE_URL from "../../../config";
import useFetch from "../../../hooks/useFetch";
import Def from "../../../assets/Def.png";

// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// ......................... Table Customization Ends ...............................//
const tableHeader = [
  { id: "photo", name: "Photo" },
  { id: "name", name: "Name" },
  { id: "email", name: "email" },
  { id: "phone", name: "Phone" },
  { id: "action", name: "Action" },
];

const Admin = () => {
  /*********************** * Pagination State ****************************/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /*********************** * Pagination State Ends****************************/

  /************************ Read All Deparments ********api/v1/auth/get-users********************/
  const { data, loading, error, reFetch } = useFetch(`/api/v1/auth/get-admin`);
  // console.log("data1", data);`

  /************************ Handle Delete ****************************/

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleDeleteDialogClose = () => {
    setSelectedUserId(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogOpen = (pId) => {
    console.log("Pid", pId);
    setSelectedUserId(pId);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async (pId) => {
    console.log("User ID to delete:", pId); // Add this line to log the user ID
    try {
      const { data } = await axios.delete(
        `${BASE_URL}/api/v1/auth/delete-user/${pId}`
      );
      if (data?.success) {
        toast.success("Successfully deleted the User.");
        reFetch();
      } else {
        toast.error("Error deleting the User");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete the User.");
    }
  };

  /************************ Handle Delete Ends****************************/

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {error && (
            <Typography variant="body1" color="error">
              {error.message}
            </Typography>
          )}
          <>
            <Box m="1.5rem 2.5rem">
              <Header
                title="पदाधिकारी"
                subtitle="व्यवस्थापन"
                style={{ textDecoration: "none" }}
              />
              <Link to="/dashboard/create-admin">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{ m: 2 }}
                >
                  यूजर थप गर्नुहोस्
                </Button>
              </Link>
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "60rem",
                }}
              >
                <Table stickyHeader arial-name="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      {tableHeader.map((value) => (
                        <StyledTableCell
                          key={value.id}
                          align={value.align}
                          style={{ minWidth: value.minWidth }}
                        >
                          {value.name}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.result &&
                      data.result
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((official, i) => {
                          return (
                            <StyledTableRow key={i}>
                              {/* map function */}
                              <StyledTableCell>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={
                                    official.file
                                      ? `${BASE_URL}/${official.file}`
                                      : Def
                                  }
                                  sx={{ height: "60px", width: "60px" }}
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                {official?.name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {official?.email}
                              </StyledTableCell>
                              <StyledTableCell>
                                {official?.phone}
                              </StyledTableCell>
                              <StyledTableCell>
                                {/* <IconButton
                                  onClick={() =>
                                    navigate(`/official/${official._id}`)
                                  }
                                >
                                  <Edit />
                                </IconButton> */}
                                <IconButton
                                  onClick={() =>
                                    handleDeleteDialogOpen(official._id)
                                  }
                                >
                                  {/* {console.log("Id", official._id)} */}
                                  <Delete />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data && data.data ? data.data.length : 0} // Add null checks to prevent accessing properties of undefined
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* Warning Message before deleting Designation */}
              <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Deletion</DialogTitle>
                <DialogContent>
                  <Typography variant="body1">
                    Are you sure you want to delete this User? This action
                    cannot be undone.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleDelete(selectedUserId);
                      handleDeleteDialogClose();
                    }}
                    color="secondary"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </>
        </Box>
      )}
    </>
  );
};

export default Admin;
