import {
  AgricultureOutlined,
  AccountBalanceOutlined,
  StoreOutlined,
  MailOutline,
  NewspaperRounded,
  CardGiftcard,
  SportsKabaddiRounded,
  ConnectedTvRounded,
  PeopleOutlineTwoTone,
  HouseRounded,
  FestivalTwoTone,
  Webhook,
  PermMediaRounded,
  Yard,
  Mode,
  HealthAndSafetyRounded,
  CastleRounded,
  Diversity3Rounded,
  AcUnitRounded,
  CycloneRounded,
  WidgetsRounded,
  MonetizationOnRounded,
  PetsRounded,
  BabyChangingStationRounded,
  ConnectWithoutContactRounded,
  Diversity2Rounded,
  LandslideRounded,
  ForestRounded,
  TransferWithinAStationRounded,
} from "@mui/icons-material";
import Gov from "../assets/Gov.png";
import Logo from "../assets/Logo.png";
const Icons = [
  { id: 1, value: <AgricultureOutlined />, label: "AgricultureOutlined" },
  { id: 2, value: <AccountBalanceOutlined />, label: "Account Balance Icon" },
  { id: 3, value: <StoreOutlined />, label: "Store Icon" },
  { id: 4, value: <HouseRounded />, label: "House Icon" },
  { id: 5, value: <MailOutline />, label: "mail Icon" },
  { id: 6, value: <CardGiftcard />, label: "Card Icon" },
  { id: 7, value: <Mode />, label: "Mode Icon" },
  { id: 8, value: <Yard />, label: "Yard Icon" },
  { id: 9, value: <SportsKabaddiRounded />, label: "sports Icon" },
  { id: 10, value: <NewspaperRounded />, label: "News Icon" },
  { id: 11, value: <ConnectedTvRounded />, label: "Tv Icon" },
  { id: 12, value: <FestivalTwoTone />, label: "Festival Icon" },
  { id: 13, value: <Webhook />, label: "webhook Icon" },
  { id: 14, value: <PermMediaRounded />, label: "Media Icon" },
  { id: 15, value: <PeopleOutlineTwoTone />, label: "People Icon" },
  { id: 16, value: <HealthAndSafetyRounded />, label: "Health Icon" },
  { id: 17, value: <CastleRounded />, label: "Castle Icon" },
  { id: 18, value: <Diversity3Rounded />, label: "Diversity Icon" },
  { id: 19, value: <AcUnitRounded />, label: "AcUnit Icon" },
  { id: 20, value: <CycloneRounded />, label: "Cyclone Icon" },
  { id: 21, value: <WidgetsRounded />, label: "Widgets Icon" },
  {
    id: 22,
    value: <MonetizationOnRounded />,
    label: "Monetization Icon",
  },
  { id: 23, value: <PetsRounded />, label: "Pets Icon" },
  {
    id: 24,
    value: <BabyChangingStationRounded />,
    label: "BabyChangingStation Icon",
  },
  {
    id: 25,
    value: <ConnectWithoutContactRounded />,
    label: "ConnectWithoutContact Icon",
  },
  {
    id: 26,
    value: <Diversity2Rounded />,
    label: "Diversity2 Icon",
  },
  {
    id: 27,
    value: <LandslideRounded />,
    label: "Landslide Icon",
  },
  {
    id: 28,
    value: <ForestRounded />,
    label: "Forest Icon",
  },
  {
    id: 29,
    value: <TransferWithinAStationRounded />,
    label: "TransferWithinAStation Icon",
  },
  {
    id: 30,
    value: <img src={Gov} alt="Gov" />, // Example of using an image file
    label: "Government Icon",
  },
  {
    id: 31,
    value: <img src={Logo} alt="Logo" />, // Example of using another image file
    label: "Logo Icon",
  },
  // Add more items as needed
];

export default Icons;
