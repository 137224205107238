import { Button, Container, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const FormContainer = styled("div")({
  marginTop: "2rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Form = styled("form")({
  width: "100%",
  maxWidth: 400,
});

const SubmitButton = styled(Button)({
  marginTop: "1rem",
});

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/auth/forgot-password`,
        { email }
      );
      if (response.status) {
        alert("Check your email for reset password link");
        navigate("/login");
      }
      console.log("response of forget pwd", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <FormContainer>
        <Typography variant="h5">Enter Your Email To Reset Password</Typography>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginBottom: "1rem" }}
          />

          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Submit
          </SubmitButton>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default ForgotPassword;
