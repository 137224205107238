// Import necessary components and dependencies
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Header from "../../../component/Header";
import { flexCenter } from "../../../component/commonStyles";
import BASE_URL from "../../../config";

// Styles for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// ......................... Table Customization Ends ...............................//

const tableHeader = [
  { id: 1, name: "NAME" },
  { id: 2, name: "Actions" },
];

const Designation = () => {
  // State variables
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [selectedId, setSelectedId] = useState(null); // State to hold selected designation ID
  const [designation, setDesignation] = useState([]); // State to hold designations data
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete dialog
  const [isCreating, setIsCreating] = useState(false); // State to track if it's for creating or updating
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  // Function to fetch all designations
  const getAllDesignation = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/designations/get-designations`
      );
      // console.log("designation", data);
      if (data?.success) {
        setDesignation(data?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Fetch all designations on component mount
  useEffect(() => {
    getAllDesignation();
  }, []);

  // Function to handle opening modal and setting default value
  const handleOpen = (id, defaultValue) => {
    setOpen(true);
    setValue(defaultValue); // Set the default value
    setSelectedId(id); // Set the selected designation ID
    setIsCreating(!id); // If id is null, it means it's for creating
  };

  // Function to handle closing modal
  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
    setValue("");
    setIsCreating(false);
  };

  // Function to handle submission of updated value or creation
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isCreating) {
        // Creating new department
        const { data } = await axios.post(
          `${BASE_URL}/api/v1/designations/create-designations`,
          { name: value }
        );
        if (data.success) {
          toast.success("New department created");
          getAllDesignation(); // Fetch updated list of designations
          handleClose(); // Close the modal after submission
        } else {
          toast.error(data.message);
        }
      } else {
        // Updating existing department
        const { data } = await axios.put(
          `${BASE_URL}/api/v1/designations/update-designations/${selectedId}`,
          { name: value }
        );
        if (data?.success) {
          toast.success(`${value} is updated`);
          getAllDesignation(); // Fetch updated list of designations
          handleClose(); // Close the modal after submission
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in Designation Page");
    }
  };
  // Function to handle searching of designations
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to filter data based on search query
  const filteredDesignations = designation.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle opening delete dialog
  const handleDeleteDialogOpen = (id) => {
    setDeleteDialogOpen(true);
    setSelectedId(id); // Set the selected designation ID for deletion
  };

  // Function to handle closing delete dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedId(null); // Reset selected designation ID
  };

  // Function to handle deletion of designation
  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(
        `${BASE_URL}/api/v1/designations/delete-designations/${selectedId}`
      );
      if (data.success) {
        toast.success("Designation is deleted");
        getAllDesignation(); // Fetch updated list of designations
        setDeleteDialogOpen(false); // Close the delete dialog
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  /*********************** Pagination State ****************************/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /*********************** Pagination State Ends****************************/

  return (
    <Box m="1.5rem 2.5rem">
      <Box>
        <Header title="पद" subtitle="व्यवस्थापन" />
        <Toolbar>
          <TextField
            label="Search Designation"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: "70%", m: 2 }}
          />
         
          <Button
            variant="contained"
            size="large"
            color="primary"
            startIcon={<Add />}
            onClick={() => handleOpen(null, "")} // Pass null as id and empty string as default value for creating new department
            sx={{ m: 2 }}
          >
            Add New
          </Button>
        </Toolbar>
      </Box>

      <TableContainer
        sx={{
          ...flexCenter,
          maxHeight: "60rem",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              {tableHeader.map((value) => (
                <StyledTableCell
                  key={value.id}
                  align={value.align}
                  style={{ minWidth: value.minWidth }}
                >
                  {value.name}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filteredDesignations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((c, i) => {
                return (
                  <StyledTableRow key={i}>
                    {/* map function */}
                    <StyledTableCell>{c?.name}</StyledTableCell>

                    <StyledTableCell>
                      <IconButton
                        onClick={() => handleOpen(c._id, c?.name)} // Pass ID and default value to handleOpen
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteDialogOpen(c._id)} // Pass ID to handleDeleteDialogOpen
                      >
                        <Delete />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={designation?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal for editing designation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add/Edit Designation
          </Typography>
          <TextField
            id="modal-modal-description"
            label="Designation Name"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }}>
            Submit
          </Button>
        </Box>
      </Modal>

      {/* Dialog for confirmation before deletion */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Designation</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this designation? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Designation;
