import { Box } from "@mui/material";
import React from "react";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Carousel from "../../component/carousel/Carousel";
import Hero from "../../component/hero/Hero";

const Home = () => {
  // const [auth, setAuth] = useAuth();
  // console.log("hello i am auth from home", auth);

  return (
    <Box >
      {/* <Typography variant="pre">{JSON.stringify(auth, null, 4)}</Typography> */}
      <Hero />
      <Carousel />
    </Box>
  );
};

export default Home;
