import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableContainer,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { flexCenter } from "../../../component/commonStyles";
import BASE_URL from "../../../config";

const WardLetterUpdate = () => {
  const { id } = useParams();
  const [values, setValues] = useState({
    service: "",
    document: "",
    price: "",
    serviceTime: "",
    provider: "",
    official: "",
    remarks: "",
  });
  const navigate = useNavigate();

  /****************Getting All Letter*******************/

  useEffect(() => {
    // console.log("_id", pid);
    getSingleLetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSingleLetter = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/ward-letters/get-ward-letters/${id}`
      );
      // console.log("Get Single Letter", data);
      setValues(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // ...................Function to Update Official ..........................//

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        service: values.service,
        document: values.document,
        price: values.price,
        serviceTime: values.serviceTime,
        provider: values.provider,
        official: values.official,
        remarks: values.remarks,
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/v1/ward-letters/update-ward-letters/${id}`,
        updatedData
      );

      if (data?.success) {
        toast.success("Details successfully updated");
        navigate("/dashboard/ward");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to update details due to technical reasons");
    }
  };

  // ...................Function to Update Official Ends..........................//

  return (
    <Box m="1.5rem 2.5rem">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table"></Table>
      </TableContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel>सेवा सुविधाको प्रकार</InputLabel>
          <TextField
            required
            variant="standard"
            name="service"
            type="text"
            value={values?.service}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                service: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            सेवा ग्राहीले पुर्‍याउनु पर्ने प्रक्रिया र प्रमाणहरु
          </InputLabel>
          <TextField
            required
            variant="standard"
            name="document"
            type="text"
            value={values?.document}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                document: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>लाग्ने शुल्करदस्तुर </InputLabel>
          <TextField
            required
            variant="standard"
            name="serviceTime"
            type="text"
            value={values?.serviceTime}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                serviceTime: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>लाग्ने समय </InputLabel>
          <TextField
            required
            variant="standard"
            name="price"
            type="text"
            value={values?.price}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                price: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>उजुरी सन्ने अधिकारी </InputLabel>
          <TextField
            required
            variant="standard"
            name="provider"
            type="text"
            value={values?.provider}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                provider: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>सेवा प्रवाह गर्ने अधिकारी / शाखा </InputLabel>
          <TextField
            required
            variant="standard"
            name="official"
            type="text"
            value={values?.official}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                official: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>कैफियत </InputLabel>
          <TextField
            required
            variant="standard"
            name="remarks"
            type="text"
            value={values?.remarks}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                remarks: e.target.value,
              }))
            }
            fullWidth
          />
        </Grid>
      </Grid>
      <Box sx={{ ...flexCenter }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          type="Submit"
          sx={{ mt: 2, mx: 2 }}
          onClick={handleUpdate}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default WardLetterUpdate;
