import { LanguageOutlined } from "@mui/icons-material";
import { AppBar, Box, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { flexCenter } from "../../commonStyles";
const Footer = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
        mt: "auto",
        height: "50px",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          <Paper sx={{ backgroundColor: "transparent", mb: 4 }}>
            <Link to="#" style={{ textDecoration: "none" }}>
              <Typography
                variant="subtitle2"
                component="h6"
                color="white"
                fontWeight="bold"
              >
                पाँचपोखरी थाङपाल गाउँपालिका &copy; डिजिटल नागरिक बडापत्र
              </Typography>
            </Link>
          </Paper>
          <Paper sx={{ ...flexCenter, backgroundColor: "transparent", mb: 4 }}>
            <LanguageOutlined sx={{ color: "white" }} />
            <Typography
              variant="subtitle2"
              component="h6"
              color="white"
              fontWeight="bold"
            >
              {/* <Button
                variant="contained"
                startIcon={<LanguageOutlined />}
                sx={{ mx: 1 }}
              > */}
              Skill Road Investment & Trading Pvt. Ltd.
              {/* </Button> */}
            </Typography>
          </Paper>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Footer;
