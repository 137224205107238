import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { flexCenter } from "../../../component/commonStyles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../config";
import toast from "react-hot-toast";

const PalikaLetterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    service: "",
    document: "",
    price: "",
    serviceTime: "",
    provider: "",
    official: "",
    remarks: "",
  });

  const { isSubmitted } = useState(false);
  const handleInputChange = (e) => {
    if (!isSubmitted) {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/palika-letters/create-palika-letters`,
        formData
        
      );
      // console.log("res",response)
      if (response?.data?.success) {
        // console.log("Response from backend:", response.data);
        toast.success("नयँ विवरण थप भयो ");
        navigate("/dashboard/palika");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box m="1.5rem 2.5rem" sx={{ backgroundColor: "default" }}>
        <Typography variant="h4" component="h2" marginTop={5} marginBottom={3}>
          गाउँपालिकाको नागरिक बडापत्र
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel>सेवा सुविधाको प्रकार</InputLabel>
            <TextField
              required
              variant="standard"
              fullWidth
              name="service"
              value={formData.service}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-standard-label">
              सेवा ग्राहीले पुर्‍याउनु पर्ने प्रक्रिया र प्रमाणहरु
            </InputLabel>
            <TextField
              required
              variant="standard"
              fullWidth
              name="document"
              value={formData.document}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="demo-simple-select-standard-label">
              लाग्ने शुल्करदस्तुर
            </InputLabel>
            <TextField
              variant="standard"
              fullWidth
              name="price"
              value={formData.price}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="demo-simple-select-standard-label">
              लाग्ने समय
            </InputLabel>
            <TextField
              variant="standard"
              fullWidth
              name="serviceTime"
              value={formData.serviceTime}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="demo-simple-select-standard-label">
              सेवा प्रवाह गर्ने अधिकारी / शाखा
            </InputLabel>
            <TextField
              id="email"
              variant="standard"
              fullWidth
              name="provider"
              value={formData.provider}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel id="demo-simple-select-standard-label">
              उजुरी सन्ने अधिकारी
            </InputLabel>
            <TextField
              variant="standard"
              name="official"
              fullWidth
              value={formData.official}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="demo-simple-select-standard-label">
              कैफियत
            </InputLabel>
            <TextField
              variant="standard"
              fullWidth
              name="remarks"
              value={formData.remarks}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
        </Grid>
        <Box sx={{ ...flexCenter }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="Submit"
            sx={{ mt: 2, mx: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PalikaLetterForm;
