import { Add, Delete, Edit, Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Def from "../../../assets/Def.png";
import Header from "../../../component/Header";
import BASE_URL from "../../../config";
import useFetch from "../../../hooks/useFetch";

// ......................... Table Customization ...............................//
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// ......................... Table Customization Ends ...............................//
const tableHeader = [
  { id: "photo", name: "Photo" },
  {
    id: "official/elected-official",
    name: "Official / Elected Official",
  },
  { id: "name", name: "Name" },
  { id: "official", name: "Department" },
  { id: "designation", name: "Designation" },
  { id: "phone", name: "Phone" },
  { id: "email", name: "Email" },
  { id: "weight", name: "Weight" },
  { id: "action", name: "Action" },
];

const Official = () => {
  const navigate = useNavigate();
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination State
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Fetch Data
  const { data, loading, error, reFetch } = useFetch(
    `/api/v1/officials/get-officials`
  );

  // Handle Delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedOfficialId, setSelectedOfficialId] = useState(null);

  const handleDeleteDialogClose = () => {
    setSelectedOfficialId(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogOpen = (oId, dId) => {
    setSelectedOfficialId({ oId, dId: dId ? dId : null });
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    const { oId, dId } = selectedOfficialId;

    try {
      const url = dId
        ? `${BASE_URL}/api/v1/officials/delete-officials/${oId}/${dId}`
        : `${BASE_URL}/api/v1/officials/delete-elected-officials/${oId}`;

      const { data } = await axios.delete(url);
      if (data?.success) {
        toast.success("Successfully deleted the official.");
        reFetch();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete the official.");
    }
  };

  // Function to filter data based on search query
  const filteredData = data?.data?.filter((official) =>
    official.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle Search
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {error && (
            <Typography variant="body1" color="error">
              {error.message}
            </Typography>
          )}
          <>
            <Box m="1.5rem 2.5rem">
              <Box>
                <Header title="पदाधिकारी" subtitle="व्यवस्थापन" />
                <Toolbar>
                  <TextField
                    label="Search by Name"
                    variant="outlined"
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: "70%", m: 2 }}
                  />
                  <Link
                    to="/dashboard/create-official"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      startIcon={<Add />}
                      sx={{ m: 2 }}
                    >
                      पदाधिकारी थप
                    </Button>
                  </Link>
                </Toolbar>
              </Box>

              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "60rem",
                }}
              >
                <Table stickyHeader arial-name="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      {tableHeader.map((value) => (
                        <StyledTableCell
                          key={value.id}
                          align={value.align}
                          style={{ minWidth: value.minWidth }}
                        >
                          {value.name}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData &&
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((official, i) => {
                          return (
                            <StyledTableRow key={i}>
                              {/* map function */}
                              <StyledTableCell>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={
                                    official.file
                                      ? `${BASE_URL}/${official.file}`
                                      : Def
                                  }
                                  sx={{ height: "60px", width: "60px" }}
                                />
                              </StyledTableCell>

                              <StyledTableCell>{official.role}</StyledTableCell>
                              <StyledTableCell>{official.name}</StyledTableCell>
                              <StyledTableCell>
                                {official?.department?.name || ""}
                              </StyledTableCell>
                              <StyledTableCell>
                                {official?.designation?.name || ""}
                              </StyledTableCell>
                              <StyledTableCell>
                                {official.phone}
                              </StyledTableCell>
                              <StyledTableCell>
                                {official.email}
                              </StyledTableCell>
                              <StyledTableCell>
                                {official.weight}
                              </StyledTableCell>

                              <StyledTableCell>
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/official/${official._id}`
                                    )
                                  }
                                >
                                  <Edit />
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    handleDeleteDialogOpen(
                                      official._id,
                                      official.department
                                        ? official.department._id
                                        : null
                                    )
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* Warning Message before deleting Designation */}
              <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Deletion</DialogTitle>
                <DialogContent>
                  <Typography variant="body1">
                    Are you sure you want to delete this official? This action
                    cannot be undone.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleDelete(selectedOfficialId);
                      handleDeleteDialogClose();
                    }}
                    color="secondary"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </>
        </Box>
      )}
    </>
  );
};

export default Official;
