import React from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const GoBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<ArrowBackIcon />}
      onClick={handleGoBack}
      style={{
        position: "fixed",
        bottom: "100px",
        right: "100px",
        zIndex: 1000,
      }}
    >
      Go Back
    </Button>
  );
};

export default GoBackButton;
