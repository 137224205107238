import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";
import { useParams } from "react-router-dom";
import BASE_URL from "../../../config";

const UpdatePassword = () => {
  const { id } = useParams();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a request to update the password
      const response = await axios.post(
        `${BASE_URL}/api/v1/auth/update-password/${id}`,
        {
          oldPassword,
          newPassword,
        }
      );
      if (response?.data?.success) {
        toast.success("Password updated successfully");
        // Display success toast

        // Reset form fields
        setOldPassword("");
        setNewPassword("");
      } else {
        toast.error("error");
      }
    } catch (error) {
      // Display error toast
      toast.error("Unable to update details due to technical reasons");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Old Password"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "1rem" }}
        >
          Update Password
        </Button>
      </form>
    </div>
  );
};

export default UpdatePassword;
